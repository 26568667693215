.image-slide-show {
    position: relative;
    margin: 0px;
}
.image-slide-show .carousel-wrapper {
    position: relative;
}
.image-slide-show .images-div {
    width: 100%;
    position: relative;
}
.image-slide-show .images-div:before {
    content: "";
    display: block;
    padding-top: 29.41176%;
}
.image-slide-show .images-div a {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.image-slide-show .images-div .active {
    display: block;
}
.image-slide-show .carousel-indicators {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    height: auto;
    overflow: hidden;
    text-align: center;
    border-width: 0;
    border-style: solid;
    border-color: #e3e3e3;
}
.image-slide-show .carousel-indicators li {
    width: 10px;
    height: 10px;
    display: inline-block;
    float: none;
    margin: 12px 6px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ffd900;
    font-size: 0;
    box-shadow: none;
    cursor: pointer;
}
.image-slide-show .carousel-indicators .active {
    background-color: red;
}
